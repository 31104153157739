@use 'sass:math';
@import '../../../../styles/variables';

.worldCereal-Modal {
  background: var(--base05);
}

.worldCereal-MetadataInfoTitle {
  margin: 0;
  line-height: 4rem;
  padding: 0 m(1);
  color: var(--base90);
}

.worldCereal-MetadataInfo {
  padding: m(1);
  overflow: hidden;
}

.worldCereal-MetadataInfoItemTiles {
  margin-top: m(math.div(1,3));
  font-size: $b1;
  display: flex;
  flex-direction: row;
  height: fit-content;
  flex-wrap: wrap;
}