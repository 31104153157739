@use 'sass:math';
@import '../../../styles/variables';

.ptr-ChartWrapper {
  width: 100%;
  padding: 1rem;
  background: var(--base10);
}

.ptr-ChartWrapper.is-small {
  display: flex;
}

.ptr-ChartWrapper:last-child {
  border-bottom: 0;
}

.ptr-ChartWrapper-header {
  display: flex;
  flex-direction: column;
}

.ptr-ChartWrapper.is-small .ptr-ChartWrapper-header {
  flex: 1;
}

.ptr-ChartWrapper-header > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ptr-ChartWrapper-header > div:last-child {

}

.ptr-ChartWrapper-title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--base90);
}

.ptr-ChartWrapper-subtitle {
  font-size: .8rem;
  color: var(--base70);
}

.ptr-ChartWrapper-body {
  height: 12rem;
  padding-top: .5rem;
}

.ptr-ChartWrapper.is-small .ptr-ChartWrapper-body {
  height: 5rem;
  width: 6rem;
  padding: 0;
}