@import '../../../styles/variables';

.ptr-RemovableLabelContainer {
  display: flex;
  flex-wrap: wrap;
  grid-gap: .35rem;
}

.ptr-RemovableLabel {
  display: flex;
  background: var(--base35);
  color: var(--base90);
  border-radius: .15rem;
  font-size: $b1;
  cursor: default;
}

.ptr-RemovableLabel.is-small {
  height: 1.35rem;
}

.ptr-RemovableLabel.is-active {
  background: var(--accent30);
}

.ptr-RemovableLabel.is-floating {
  box-shadow: 0 0 5px rgba(0,0,0,.7);
}

.ptr-RemovableLabel.has-strip {
  border-left: 5px solid;
}

// content
.ptr-RemovableLabel-content {
  display: flex;
  flex-direction: column;
  padding: .25rem .5rem;
}

.ptr-RemovableLabel.is-small .ptr-RemovableLabel-content {
  padding: 0.05rem .35rem;
}

.ptr-RemovableLabel.is-clickable .ptr-RemovableLabel-content {
  cursor: pointer;
}

.ptr-RemovableLabel.is-clickable .ptr-RemovableLabel-content:hover {
  background: var(--base25);
}

// button
.ptr-RemovableLabel-removeButton.ptr-button {
  min-height: .5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(var(--base50rgb), .25);
}

.ptr-RemovableLabel-removeButton.ptr-button:hover {
  background: var(--base25);
}