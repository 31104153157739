@use 'sass:math';
@import '../../../../../styles/variables';

.worldCereal-StatisticsSelect {
  width: 100%;
}

.worldCereal-StatisticsSelect svg {
  width: 16px;
}

.worldCereal-StatisticsSelect-label {
  font-size: .75rem;
  line-height: 1;
  margin-bottom: .3rem;
  letter-spacing: 0;
  color: var(--base85);
}