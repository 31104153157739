@use 'sass:math';
@import '../../../../../styles/variables';

$border: 1px solid var(--base30);

.worldCereal-Filter {
  height: 100%;
}

.worldCereal-Filter.disabled:after {
  content: "";
  @include disabledOverlay();
}

.worldCereal-Filter .ptr-PageSwitcherMenuItem {
  padding: m(math.div(1,3)) m(2) m(math.div(1,3)) m(1);
  border-bottom: $border;
}

.worldCereal-Filter .ptr-PageSwitcherMenuItem:first-child {
  border-top: $border;
}

.worldCereal-Filter .ptr-PageSwitcherContent {
  padding: m(math.div(2,3)) m(1);
  border-top: $border;
  border-left: $border;
  width: 100%;
}