@import 'variables';

.ptr-light {
	--accent0: #{$lightAccent0};
	--accent0rgb: #{hexToRGB($lightAccent0)};
	--accent05: #{$lightAccent05};
	--accent05rgb: #{hexToRGB($lightAccent05)};
	--accent10: #{$lightAccent10};
	--accent10rgb: #{hexToRGB($lightAccent10)};
	--accent15: #{$lightAccent15};
	--accent15rgb: #{hexToRGB($lightAccent15)};
	--accent20: #{$lightAccent20};
	--accent20rgb: #{hexToRGB($lightAccent20)};
	--accent25: #{$lightAccent25};
	--accent25rgb: #{hexToRGB($lightAccent25)};
	--accent30: #{$lightAccent30};
	--accent30rgb: #{hexToRGB($lightAccent30)};
	--accent35: #{$lightAccent35};
	--accent35rgb: #{hexToRGB($lightAccent35)};
	--accent40: #{$lightAccent40};
	--accent40rgb: #{hexToRGB($lightAccent40)};
	--accent45: #{$lightAccent45};
	--accent45rgb: #{hexToRGB($lightAccent45)};
	--accent50: #{$lightAccent50};
	--accent50rgb: #{hexToRGB($lightAccent50)};
	--accent55: #{$lightAccent55};
	--accent55rgb: #{hexToRGB($lightAccent55)};
	--accent60: #{$lightAccent60};
	--accent60rgb: #{hexToRGB($lightAccent60)};
	--accent65: #{$lightAccent65};
	--accent65rgb: #{hexToRGB($lightAccent65)};
	--accent70: #{$lightAccent70};
	--accent70rgb: #{hexToRGB($lightAccent70)};
	--accent75: #{$lightAccent75};
	--accent75rgb: #{hexToRGB($lightAccent75)};
	--accent80: #{$lightAccent80};
	--accent80rgb: #{hexToRGB($lightAccent80)};
	--accent85: #{$lightAccent85};
	--accent85rgb: #{hexToRGB($lightAccent85)};
	--accent90: #{$lightAccent90};
	--accent90rgb: #{hexToRGB($lightAccent90)};
	--accent95: #{$lightAccent95};
	--accent95rgb: #{hexToRGB($lightAccent95)};
	--accent100: #{$lightAccent100};
	--accent100rgb: #{hexToRGB($lightAccent100)};
}

.ptr-dark {
	--accent0: #{$darkAccent0};
	--accent0rgb: #{hexToRGB($darkAccent0)};
	--accent05: #{$darkAccent05};
	--accent05rgb: #{hexToRGB($darkAccent05)};
	--accent10: #{$darkAccent10};
	--accent10rgb: #{hexToRGB($darkAccent10)};
	--accent15: #{$darkAccent15};
	--accent15rgb: #{hexToRGB($darkAccent15)};
	--accent20: #{$darkAccent20};
	--accent20rgb: #{hexToRGB($darkAccent20)};
	--accent25: #{$darkAccent25};
	--accent25rgb: #{hexToRGB($darkAccent25)};
	--accent30: #{$darkAccent30};
	--accent30rgb: #{hexToRGB($darkAccent30)};
	--accent35: #{$darkAccent35};
	--accent35rgb: #{hexToRGB($darkAccent35)};
	--accent40: #{$darkAccent40};
	--accent40rgb: #{hexToRGB($darkAccent40)};
	--accent45: #{$darkAccent45};
	--accent45rgb: #{hexToRGB($darkAccent45)};
	--accent50: #{$darkAccent50};
	--accent50rgb: #{hexToRGB($darkAccent50)};
	--accent55: #{$darkAccent55};
	--accent55rgb: #{hexToRGB($darkAccent55)};
	--accent60: #{$darkAccent60};
	--accent60rgb: #{hexToRGB($darkAccent60)};
	--accent65: #{$darkAccent65};
	--accent65rgb: #{hexToRGB($darkAccent65)};
	--accent70: #{$darkAccent70};
	--accent70rgb: #{hexToRGB($darkAccent70)};
	--accent75: #{$darkAccent75};
	--accent75rgb: #{hexToRGB($darkAccent75)};
	--accent80: #{$darkAccent80};
	--accent80rgb: #{hexToRGB($darkAccent80)};
	--accent85: #{$darkAccent85};
	--accent85rgb: #{hexToRGB($darkAccent85)};
	--accent90: #{$darkAccent90};
	--accent90rgb: #{hexToRGB($darkAccent90)};
	--accent95: #{$darkAccent95};
	--accent95rgb: #{hexToRGB($darkAccent95)};
	--accent100: #{$darkAccent100};
	--accent100rgb: #{hexToRGB($darkAccent100)};
}

input {
	accent-color: var(--accent60);
}

// custom scrollbar
/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--base40);
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--base50);
}