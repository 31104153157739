@use 'sass:math';
@import '../../../styles/variables';

.worldCereal-tour-steps-Container {
	padding: 0.5rem;
}

.worldCereal-tour-steps-SectionsContainer {
	display: flex;
	flex-direction: row;
}

.worldCereal-tour-steps-Section.is-right {
	margin-left: 1rem;
}

.worldCereal-tour-steps-descriptionSections {
	display: flex;
	justify-content: left;
	align-items: center;
	margin-bottom: 1rem;
}

.worldCereal-tour-steps-mapIcons .ptr-IconTool {
	cursor: default;
}

.worldCereal-tour-steps-mapIconsDescription {
	margin-bottom: 0;
}

.worldCereal-tour-steps-mapIcons {
	height: fit-content;
}