@import '../../../styles/variables';

.ptr-OpacitySlider {
  width: 5rem;
  height: .3rem;
  border-radius: 5rem;
  margin-right: .25rem;
}

.ptr-OpacitySlider-thumb {
  margin-top: -0.65rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: .85rem;
  background: var(--base70);
  color: var(--accent10);
  border: 2px solid var(--base70);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $b1;
  cursor: pointer;
}

.ptr-OpacitySlider.disabled .ptr-OpacitySlider-thumb {
  background: var(--base30);
  color: var(--base50);
  border: 2px solid var(--base30);
}

.ptr-OpacitySlider-thumb:hover {
  background: var(--base50);
}

.ptr-OpacitySlider-track {
  top: 0;
  bottom: 0;
  border-radius: 999px;
}

.ptr-OpacitySlider-track-0 {
  background: var(--base70);
}

.ptr-OpacitySlider-track-1 {
  background: var(--base30);
}

.ptr-OpacitySlider.disabled .ptr-OpacitySlider-track-0 {
  background: var(--base30);
}