.worldCereal-GlobalProducts{
	display: flex;
	flex-flow: row;
	background: var(--base95);
	padding: .25rem 0;
	position: relative;
}

.worldCereal-GlobalProducts.is-collapsed {
	height: 0;
	padding: 0;
}

.worldCereal-GlobalProductsColumn-placeholder {
	flex: 1;
}