@use 'sass:math';
@import '../../styles/variables';

.worldCereal-ProductViewer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.worldCereal-statistics {
  display: flex;
  height: 100%;
}

.worldCereal-statistics > .ptr-map-set {
  flex: 1;
}