@use 'sass:math';
@import '../../styles/variables';

$mapBorder: 0.05rem solid var(--base50); // the width in rem fixes the ugly white space between border and content in Chrome

// Map set
.worldCereal-ProductViewer .ptr-map-set {
  position: relative;
  z-index: 2;
  margin-top: -1px;
}

.worldCereal-ProductViewer .ptr-map-grid .ptr-map-grid-cell .ptr-map {
  border: 0;
}

.worldCereal-ProductViewer .ptr-map-grid .ptr-map-grid-cell:not(.row1) .ptr-map {
  border-top: $mapBorder;
}

.worldCereal-ProductViewer .ptr-map-grid .ptr-map-grid-cell:not(.col1) .ptr-map {
  border-left: $mapBorder;
}

// Map slider
.worldCereal-CompareSlider {
  height: 100%;
  margin-top: -1px;
}

div[data-rcs="handle-container"] {
  z-index: 3;
}

div[data-rcs="clip-item"] {
  z-index: 2;
}

.ptr-map-controls-wrapper {
  z-index: auto;
}

// MapInfoElements
.worldCereal-MapInfoElements {
  pointer-events: none;
  position: absolute;
  bottom: .35rem;
  left: .35rem;
  display: flex;
  grid-gap: .35rem;
  flex-direction: column;
  z-index: 9;
}

// AttributionScaleContainer
.worldCereal-AttributionScaleContainer {
  display: flex;
  grid-gap: .35rem;
  position: relative;
}
.worldCereal-AttributionScaleContainer.is-shifted {
  left: 2.75rem;
}

// Map set controls
.worldCereal-MapSetControls {
  position: absolute;
  right: .5rem;
  bottom: .5rem;
  grid-gap: .5rem;
  display: flex;
  flex-direction: column;
  z-index: 9;

  .ptr-IconTool.is-active {
    background: var(--accent50);
    color: var(--base100);
  }

  .ptr-IconTool.is-floating:hover,
  .ptr-ZoomControls .zoom-control.control .ptr-button:hover 
   {
    background: var(--accent20);
    color: var(--base100);
    // stroke: var(--base100);
    .line {
      fill: var(--base100);
    }
  }
}