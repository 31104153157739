@use 'sass:math';
@import '../../../../../styles/variables';

// Title
.worldCereal-MapLegendTitle {
  line-height: 1;
}

.worldCereal-MapLegendTitle-name {
  font-weight: bold;
  font-size: $b1;
  color: $baseTextColor;
}

.worldCereal-MapLegendTitle-unit {
  font-size: $b1;
  margin-left: .25rem;
  color: $secondaryTextColor;
}

// Content
.worldCereal-MapLegendContent {
  display: flex;
  margin-top: .35rem;
  grid-gap: 1rem;
  padding-right: .25rem;
  width: 100%;
  align-items: center;
}

.worldCereal-MapLegendContent.is-expanded {
  flex-direction: column;
  grid-gap: .5rem;
  align-items: flex-start;
}

// Class item
.worldCereal-MapLegendClassItem,
.worldCereal-MapLegendValueItem {
  display: flex;
  grid-gap: .35rem;
}

.worldCereal-MapLegendClassItem > div {
  width: 1.25rem;
  height: .75rem;
}

.worldCereal-MapLegendValueItem > div {
  width: .75rem;
  height: .75rem;
  border-radius: .5rem;
}

.worldCereal-MapLegendClassItem > span,
.worldCereal-MapLegendValueItem > span {
  font-size: $b1;
  line-height: 1;
  color: $secondaryTextColor;
}

// Classes Bar
.worldCereal-MapLegendClassesBar {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
}

.worldCereal-MapLegendClassesBar-label {
  font-size: $b1;
  line-height: 1;
  color: $secondaryTextColor;
}

.worldCereal-MapLegendClassesBar-bar {
  display: flex;
  flex: 1;
  margin: 0 .25rem;
  height: 100%;
}

.worldCereal-MapLegendClassesBar-bar > div {
  flex: 1;
  height: .75rem;
}

.worldCereal-MapLegendClassesBar .worldCereal-MapLegendClassItem {
  margin-right: .75rem;
}

// Attribute classes
.worldCereal-MapLegend-AttributeClasses {
  display: flex;
  flex: 1;
}

.worldCereal-MapLegend-AttributeClasses.is-expanded {
  flex-direction: column;
  grid-gap: .25rem;
}

// Attribute values
.worldCereal-MapLegend-AttributeValues {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  grid-gap: .35rem;
}

.worldCereal-MapLegend-AttributeValues.is-expanded {
  flex-direction: column;
}

// Attribute scale
.worldCereal-MapLegend-AttributeScale {
  display: flex;
  flex: 1;
}

.worldCereal-MapLegend-AttributeScale.is-expanded {
  width: 100%;
}

.worldCereal-MapLegend-AttributeScale > span {
  font-size: $b1;
  line-height: 1;
  color: $secondaryTextColor;
}

.worldCereal-MapLegend-AttributeScale > div {
  flex: 1;
  height: .75rem;
  margin: 0 .25rem;
}

