@import '../../../../styles/variables';

.worldCereal-Header {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 .5rem;
  background: var(--base100);
  box-shadow: 0 0 5px rgba(var(--base100rgb), .25);
  border-bottom: 1px solid var(--base70);
  min-height: 3rem;
}

.worldCereal-Header-esaLogo {
  margin-right: .5rem;
}

.worldCereal-Header-esaLogo > svg {
  width: 3rem;
}

.worldCereal-Header-tools {
  display: flex;
  grid-gap: 1rem;
  color: var(--base10);
  align-items: center;
}

.worldCereal-MapsModeOption-compare.ptr-button.small .ptr-icon {
  transform: rotate(45deg);
  margin-right: .25rem;
}

.worldCereal-Header-tourIcon {
	color: var(--base10);
}

.worldCereal-Header-tourIcon:hover {
	color: var(--base10);
	background-color: rgba(255, 255, 255, 0.25);
}