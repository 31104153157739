@import '../../../styles/variables';

// modal
.ptr-ModalOverlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--base100rgb), .65);
  @include frozenGlassEffect();
  display: flex;
  align-items: center;
  justify-content: center;
}

.ptr-Modal{
  max-width: 90%;
  max-height: 90%;
  min-height: 15rem;
  min-width: 20rem;
  background: var(--base0);
  border-radius: .25rem;
  outline: none;
  position: relative;
  overflow-y: auto;
}

.ptr-Modal-header {
  height: 3rem;
}

.ptr-Modal-title {

}

.ptr-Modal-closeButton {
  padding: 0 .1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.ptr-Modal-content {
  max-height: calc(100% - 3rem);
  overflow: auto;
}