@use 'sass:math';
@import '../../../styles/variables';

.worldCereal-esaLogo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.worldCereal-esaLogo svg > path {
  fill: var(--base10);
}

.worldCereal-esaLogo:hover svg > path {
  fill: var(--accent30);
}