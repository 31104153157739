@use 'sass:math';
@import '../../../../styles/variables';

.worldCereal-AppConfiguration {
  padding: m(1);
  background: var(--base0);
}

.worldCereal-AppConfiguration-title {
  display: flex;
  align-items: center;
  color: $baseTextColor;
  margin-bottom: m(1);
}

.worldCereal-AppConfiguration-title > h2 {
  //font-size: $a2;
  margin: 0 0 0 .5rem;
  color: $baseTextColor;
}

.worldCereal-AppConfiguration-title > .ptr-react-icon {
  width: 1.5rem;
  height: 1.5rem;
}

// App Configuration section
.worldCereal-AppConfigurationSection:not(:last-child) {
  margin-bottom: m(1);
}

.worldCereal-AppConfigurationSection-title {
  color: $baseTextColor;
  font-size: $b2;
  margin: 0 0 .25rem;
}

