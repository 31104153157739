@use 'sass:math';
@import '../../../../styles/variables';

$statisticsPanelPaddingLeft: .75rem;
$statisticsPanelPaddingRight: 1rem;

.worldCereal-StatisticsPanel {
  background: var(--base100);
  width: 36.5rem;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.worldCereal-StatisticsPanel-header {
  display: flex;
  justify-content: space-between;
  padding: 0 .75rem;
  border-bottom: 1px solid var(--base90);
  min-height: 3rem;
}

.worldCereal-StatisticsPanel-header-isRight {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	grid-gap: 1rem;
}

.worldCereal-StatisticsPanel-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.worldCereal-StatisticsPanel-configurations {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  padding: 1rem $statisticsPanelPaddingRight .5rem $statisticsPanelPaddingLeft;
}

.worldCereal-StatisticsPanel-configurations > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: .75rem;
}

.worldCereal-StatisticsPanel-visualizations {
  flex: 1;
  padding: 1rem $statisticsPanelPaddingRight 1rem $statisticsPanelPaddingLeft;
}

